import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import ArrowRight from '$icons/arrow-right.svg';
import { ifProp } from 'styled-tools';
import { shouldNotForwardProps } from '~/shared/utils/styled';
import { StyledRichText } from '~/shared/components/RawHtml/styled';

export const StyledM100RichText = styled.div<{
    textColor: string;
}>(({ textColor }) => ({
    color: textColor,
    [`&:last-child ${StyledM100RichTextInner}`]: {
        paddingBottom: 0,
    },
}));

export const StyledM100RichTextOuter = styled('div', {
    shouldForwardProp: shouldNotForwardProps(['height', 'open']),
})<{
    isTruncated: boolean;
    height?: number;
    open: boolean;
}>(({ isTruncated, open, height }) => ({
    position: 'relative',
    overflow: 'hidden',
    transition: 'height 0.3s ease',
    height: isTruncated ? (open ? `${height}px` : '500px') : 'auto',
    padding: '0 7px', // this fixes overflow breaking button animation
    margin: '0 -7px', // this fixes overflow breaking button animation
    '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 150,
        background: 'linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
        display: isTruncated && !open ? 'block' : 'none',
    },
}));

export const StyledM100RichTextInner = styled.div(({ theme }) => ({
    paddingBottom: theme.spaces[7],
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [`${StyledRichText}`]: {
        [breakpoints.sm]: {
            '> *:first-child': {
                marginTop: 0,
            },
        },
        '> *:last-child': {
            marginBottom: 0,
        },
        '> p:last-child:empty': {
            display: 'none',
        },
    },
}));

export const StyledColumns = styled.div<{ loadOnSidepanel?: boolean }>(
    {
        [breakpoints.md]: {
            display: 'grid',
            gap: 30,
            gridAutoFlow: 'column',
            alignItems: 'start',
        },
    },
    ifProp('loadOnSidepanel', {
        [breakpoints.md]: {
            gridAutoFlow: 'row',
        },
    }),
);

export const StyledActions = styled.div({});

export const StyledToggleButtonWrapper = styled.div<{ open: boolean }>(({ open, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spaces[3],
    figure: {
        transform: open ? 'rotateX(180deg)' : 'rotateX(360deg)',
    },
}));

export const StyledArrowRight = styled(ArrowRight)({
    maxWidth: 11,
});

export const StyledUspWrapper = styled.div<{ columns?: number }>(({ columns }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gap: 35,
    [breakpoints.md]: {
        gridTemplateColumns: columns === 2 ? '1fr 1fr' : '1fr 1fr 1fr',
    },
}));

export const StyledActionWrapper = styled.div(({ theme }) => ({
    paddingTop: theme.spaces[5],
    paddingBottom: 7,
}));
