import { useMemo } from 'react';
import { usePage } from '~/templates/pages';

/**
 *
 * @param moduleId id of the current module you want the previous or next module from.
 * @returns The module placed immediately before the one references by moduleId.
 * If the specified moduleId is not found or is the first module on the page module will be null.
 */
export const useAdjacentModule = (moduleId: string) => {
    const { pageElements = [] } = usePage();

    const previousModule = useMemo(() => {
        if (!pageElements) return null;
        const moduleIndex = pageElements.findIndex((item) => item.id == moduleId);
        // If module is first or not found, return null;
        if (moduleIndex < 1) return null;

        return pageElements[moduleIndex - 1];
    }, [pageElements, moduleId]);

    const nextModule = useMemo(() => {
        if (!pageElements) return null;
        const moduleIndex = pageElements.findIndex((item) => item.id == moduleId);
        // If module is first or not found, return null;
        if (moduleIndex + 1 > pageElements.length) return null;

        return pageElements[moduleIndex + 1];
    }, [pageElements, moduleId]);

    return {
        previousModule,
        nextModule,
    };
};
