import { Button, CallToAction, RawHtml } from '$shared/components';
import { Headline } from '~/templates/blocks/components/Headline/Headline';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { M100RichTextModule } from '~/lib/data-contract';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { ModuleContainer } from '../ModuleContainer';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import {
    StyledActionWrapper,
    StyledColumns,
    StyledM100RichText,
    StyledM100RichTextInner,
    StyledM100RichTextOuter,
    StyledToggleButtonWrapper,
} from './styled';
import { useTranslation } from '$shared/utils/translation';
import { useAdjacentModule } from '../../hooks/useAdjacentModule';

export type M100RichTextProps = M100RichTextModule;

const HEIGHT_THRESHOLD = 500;

export const M100RichText = ({
    text,
    headline,
    callToAction,
    truncated = false,
    pageElementIndex,
    loadOnSidepanel,
    id,
    ...rest
}: M100RichTextProps) => {
    const innerContainerRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);
    const [open, setOpen] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const { textColor } = useModuleTheme(rest);
    let measureTimeout: NodeJS.Timeout | undefined = undefined;
    const { translate } = useTranslation();
    const { previousModule } = useAdjacentModule(id);

    const shouldRemoveSpacingTop = useMemo(() => {
        return (
            !!previousModule?.type &&
            ['m85DetailsModule', 'm100RichTextModule'].includes(previousModule.type)
        );
    }, [module]);

    useEffect(() => {
        if (!truncated) return;

        measureTimeout && clearInterval(measureTimeout);

        const measureHeight = () => {
            if (innerContainerRef && innerContainerRef.current) {
                const { height } = innerContainerRef.current.getBoundingClientRect();

                if (height > HEIGHT_THRESHOLD) {
                    setHeight(height);
                    setIsTruncated(true);
                } else {
                    setIsTruncated(false);
                }
            }
        };

        measureTimeout = setTimeout(() => measureHeight(), 1000);
        measureHeight();
        window.addEventListener('resize', measureHeight);
        return () => {
            window.removeEventListener('resize', measureHeight);
            measureTimeout && clearInterval(measureTimeout);
        };
    }, [innerContainerRef]);

    return (
        <ModuleContainer
            hasGutter
            pageElementIndex={pageElementIndex}
            {...rest}
            spacingTop={shouldRemoveSpacingTop ? 'small' : 'default'}
        >
            <StyledColumns loadOnSidepanel={loadOnSidepanel}>
                <div>{headline && <Headline variant="display3">{headline}</Headline>}</div>
                <StyledM100RichText textColor={textColor}>
                    <StyledM100RichTextOuter isTruncated={isTruncated} height={height} open={open}>
                        <StyledM100RichTextInner ref={innerContainerRef}>
                            {text && <RawHtml html={text} />}

                            {callToAction && (
                                <StyledActionWrapper>
                                    <CallToAction callToAction={callToAction} />
                                </StyledActionWrapper>
                            )}
                        </StyledM100RichTextInner>
                    </StyledM100RichTextOuter>

                    {isTruncated && (
                        <StyledToggleButtonWrapper open={open}>
                            <Button
                                title={
                                    open
                                        ? translate('Kompan.Generic.ShowLess')
                                        : translate('Kompan.Generic.ShowMore')
                                }
                                children={
                                    open
                                        ? translate('Kompan.Generic.ShowLess')
                                        : translate('Kompan.Generic.ShowMore')
                                }
                                variant="Transparent"
                                iconAlignment="start"
                                icon={'Arrow Down'}
                                onClick={() => setOpen(!open)}
                            />
                        </StyledToggleButtonWrapper>
                    )}
                </StyledM100RichText>
            </StyledColumns>
        </ModuleContainer>
    );
};

export default withErrorBoundary(M100RichText);
